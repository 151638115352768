// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-dispensaries-js": () => import("./../../src/pages/dispensaries.js" /* webpackChunkName: "component---src-pages-dispensaries-js" */),
  "component---src-pages-forgotpassword-js": () => import("./../../src/pages/forgotpassword.js" /* webpackChunkName: "component---src-pages-forgotpassword-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-dispensary-confirm-js": () => import("./../../src/pages/registerDispensaryConfirm.js" /* webpackChunkName: "component---src-pages-register-dispensary-confirm-js" */),
  "component---src-pages-register-dispensary-js": () => import("./../../src/pages/registerDispensary.js" /* webpackChunkName: "component---src-pages-register-dispensary-js" */),
  "component---src-pages-register-js": () => import("./../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-search-js": () => import("./../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-dispensary-index-js": () => import("./../../src/templates/dispensary/index.js" /* webpackChunkName: "component---src-templates-dispensary-index-js" */),
  "component---src-templates-dispensary-listing-by-city-index-js": () => import("./../../src/templates/dispensaryListingByCity/index.js" /* webpackChunkName: "component---src-templates-dispensary-listing-by-city-index-js" */),
  "component---src-templates-dispensary-listing-by-state-index-js": () => import("./../../src/templates/dispensaryListingByState/index.js" /* webpackChunkName: "component---src-templates-dispensary-listing-by-state-index-js" */),
  "component---src-templates-dispensary-menu-index-js": () => import("./../../src/templates/dispensaryMenu/index.js" /* webpackChunkName: "component---src-templates-dispensary-menu-index-js" */),
  "component---src-templates-dispensary-reviews-index-js": () => import("./../../src/templates/dispensaryReviews/index.js" /* webpackChunkName: "component---src-templates-dispensary-reviews-index-js" */)
}

